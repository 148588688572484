
import BtnMain from '@/components/parts/button/BtnMain.vue'
// import PlusSVG from '@/components/svg/icon/PlusSVG.vue'
import RowSVG from '@/components/svg/careers/RowSVG.vue'
import ArrowRightSVG from '@/components/svg/icon/ArrowRightSVG.vue'
import LineSVG from '~/components/svg/LineSVG.vue'
export default {
  components: {
    BtnMain,
    // PlusSVG,
    RowSVG,
    ArrowRightSVG,
    LineSVG,
  },
  props: {
    job: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tl: null,
    }
  },
  mounted() {
    // this.$nuxt.$on('go', this.anim)
    this.tl = this.$gsap.timeline({
      scrollTrigger: {
        trigger: this.$el,
        // toggleActions: 'play reset play reverse',
        // markers: true,
        // start: 'top center',
      },
    })
    // this.tl.scrollTrigger.disable()
    this.tl.from(this.$el, { translateX: 64, opacity: 0, duration: 0.2 })
  },
  // beforeDestroy() {
  //   this.$nuxt.$off('go', this.anim)
  // },
  // methods: {
  //   anim() {
  //     this.tl.scrollTrigger.enable()
  //   },
  // },
}
