
import { parse } from 'marked'
import JobListing from '@/components/parts/jobs/JobListing.vue'
import DropDown from '@/components/parts/jobs/DropDown.vue'
import BodyWrapOuter from '@/components/wrappers/BodyWrapOuter.vue'
import BodyWrap from '@/components/wrappers/BodyWrap.vue'
import { parseJobs } from '@/utils/pinpoint'
import InfoWrap from '@/components/wrappers/InfoWrap.vue'
export default {
  components: {
    DropDown,
    JobListing,
    BodyWrap,
    BodyWrapOuter,
    InfoWrap,
  },
  props: {
    section: {
      type: Object,
      default: () => {},
    },
    filtered: {
      type: Boolean,
      default: false,
    },
    department: {
      type: String,
      default: '',
    },
    currentJob: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      jobs: [],
      deps: [],
      selectedDep: null,
      showAll: !this.section?.count,
      animating: false,
      sm: null,
    }
  },
  async fetch() {
    const endpoint = `${this.$config.API_URL}${this.$config.PINPOINT_URL}/jobs`
    const response = await this.$api.get(endpoint)
    const data = parseJobs(response)

    this.deps = data.departments
    this.selectedDep = this.deps[0]
    this.jobs = data.jobs

    // this.$nextTick(() => {
    //   console.log('job refrsh')
    //   this.$ScrollTrigger.refresh()
    // })
  },
  fetchOnServer: false,
  computed: {
    jobList() {
      let jobs = []

      if (this.department) {
        // department passed in
        jobs = this.jobs.filter((job) => job.department === this.department && job.id !== this.currentJob.id)
        // no other jobs in same department, fall back to all
        if (!jobs.length) jobs = this.jobs
      } else if (this.selectedDep && this.selectedDep.attributes.name !== 'All') {
        // filtering by department
        jobs = this.jobs.filter((job) => job.department === this.selectedDep.attributes.name)
      } else {
        jobs = this.jobs
      }
      return jobs
    },
    slicedJobList() {
      return this.showAll ? this.jobList : this.jobList.slice(0, this.defaultLimit)
    },
    bodyText() {
      return this.section?.body ? parse(this.section.body) : ''
    },
    defaultLimit() {
      return this.section?.count ? this.section.count : 1000
    },
  },
  watch: {
    slicedJobList() {
      this.$nextTick(() => {
        this.$ScrollTrigger.refresh()
      })
    },
  },
  methods: {
    updateSelected(selected) {
      this.selectedDep = selected
    },
    // // transition-group
    // onBeforeEnter(el) {
    //   this.$gsap.set(el, {
    //     opacity: 0,
    //     translateX: -64,
    //   })
    // },
    // onEnter(el, done) {
    //   this.$gsap.to(el, {
    //     opacity: 1,
    //     translateX: 0,
    //     delay: el.dataset.index * 0.05,
    //     onComplete: done(),
    //   })
    // },
    onLeave(el, done) {
      this.$gsap.to(el, {
        opacity: 0,
        translateX: -64,
        delay: el.dataset.index * 0.05,
        onComplete: done(),
      })
    },
  },
}
