
import ChevDownSVG from '@/components/svg/icon/ChevDownSVG.vue'

export default {
  name: 'Dropdown',
  components: { ChevDownSVG },
  props: {
    options: {
      default: () => {},
      type: Array,
    },
    selected: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      open: false,
      value: this.selected,
    }
  },
  mounted() {
    if (this.options.length === 1) {
      this.setValue(this.options[0])
    }
  },
  methods: {
    toggleOpen() {
      this.open = !this.open
    },
    setValue(value) {
      this.value = value
      this.$emit('setValue', value)
    },
  },
}
