export const parseJobs = (response) => {
  const jobs = response.data.data
  const deps = [
    {
      attributes: {
        name: 'All',
      },
    },
  ]
  const locs = []
  response.data.included.forEach((i) => {
    if (i.type === 'departments') deps.push(i)
    if (i.type === 'locations') locs.push(i)
  })

  const parse = (job) => {
    // add department name to job as only id job response
    const depID = job.relationships.department.data.id
    job.department = deps.find((dep) => dep.id === depID).attributes.name
    // add location to name to job as only id in job response
    const locID = job.relationships.location.data.id
    job.location = locs.find((loc) => loc.id === locID).attributes.city
    // add 'Hybrid/'
    job.workType = job.attributes.workplace_type_text
    // adding ID of portfolio field here. TODO: in v1 this will need to be dynamic quesitons related to the job
    if (job.relationships.questions.data.length) job.portfolioFieldID = job.relationships.questions.data[0].id

    return job
  }

  jobs.forEach((job) => {
    job = parse(job)
  })
  return {
    departments: deps.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name)),
    jobs: jobs.sort((a, b) => a.department.localeCompare(b.department)),
  }
}

export const parseJob = (response) => {
  const job = response.data.data
  let deps = {}
  let locs = {}
  response.data.included.forEach((i) => {
    if (i.type === 'departments') deps = i
    if (i.type === 'locations') locs = i
  })

  // add department name to job as only id job response
  job.department = deps.attributes
  // add location to name to job as only id in job response
  job.location = locs.attributes.city
  // add 'Hybrid/'
  job.workType = job.attributes.workplace_type_text
  // adding ID of portfolio field here. TODO: in v1 this will need to be dynamic quesitons related to the job
  if (job.relationships.questions.data.length) job.portfolioFieldID = job.relationships.questions.data[0].id

  return job
}
